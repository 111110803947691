@import "./base.scss";
.skills {
    position: relative;
    font-size: 40px;
    height: 300px;
    background: $yellow-color;
    overflow: hidden;
    text-align: center;
    padding-top: 1rem;
    &__header {
        font-weight: 600;
    }
    &__text {
        position: absolute;
        white-space: nowrap;
        font-family: sans-serif;
        &--left {
            left: 0;
            top: 6rem;
            animation: moveleft 30s linear infinite;
            @keyframes moveleft {
                0%{
                    transform: translate3d(0,0,0);
                }
                100%{
                    transform: translate3d(-50%,0,0);
                }
            }
        }
        &--right {
            right: 0;
            top: 11rem;
            animation: moveright 30s linear infinite;
            @keyframes moveright {
                0%{
                    transform: translate3d(0,0,0);
                }
                100%{
                    transform: translate3d(50%,0,0);
                }
            }
        }
    }
    @media (max-width:700px) {
        & {
            height: 200px;
        }
        &__text {
            font-size: 25px;
            &--left {
                top: 5rem;
            }
            &--right {
                top: 8rem;
            }
        }
    }
}