@import "./base.scss";
.reviews {
    position: relative;
    background: $darkish-blue;
    color: #fff;
    &__headers {
        display: flex;
        align-items: center;
        gap: 1rem;
        .instructor {
            position: relative;
            background: #28316013;
            box-shadow: 0 8px 32px 0 $box-shadow-blue1;
            &__curve {
                position: absolute;
                top: -10px;
                left: 20px;
                width: 28%;
                height: 120%;
                border-left: 2px solid $red-color;
                border-radius: 50%;
                transform: rotate(-20deg);
                box-shadow: 0 8px 32px 0 $box-shadow-blue2;
            }
            &__infos {
                display: flex;
                align-items: center;
                gap: 15px;
                font-size: 17px;
                font-family: sans-serif;
            }
            &__img {
                width: 91.5px;
                height: 110px;
                border-radius: 50%;
                border-right: 2px solid $red-color;
                @media (min-width:400px) {
                    & {
                        width: 125px;
                        height: 150px;
                    }
                } 
            }
            &__col {
                display:flex;
                flex-direction: column;
                gap: 5px;            
            }
            &__info {
                display:flex;
                align-items: center;
                gap: 10px;
                & img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
.swiper__wrapper {
    padding: 2rem 0;
}
.swiper {
    user-select: none;
}
.review {
    position: relative;
    display: flex;
    font-size: 16px;
    padding: 5px;
    &__card {
        position: relative;
        width: 100%;
        padding: 2rem 0;
        display: flex;
        @media (min-width:650px) {
            font-size: 18;
            font-family: sans-serif;
        }
    }
    & svg {
        width: 30px;
        fill: $red-color;
        @media (min-width:500px) {
            & {
                width: 40px;
            }
        }
    }
    &__topborder {
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
    }
    &__text {
        span {
            font-family: serif;
            font-size: 30px;
            font-weight: 600;
            color: $red-color;
        }
    }
    &__img {
        width: 70px;
        height: 100px;
        object-fit: cover;
        border-radius: 35px;
    }
    &__profile {
        position: absolute;
        left: 5rem;
        bottom: -2rem;
        display: flex;
        flex-direction: column;
        font-family: sans-serif;
        font-size: 17px;
    }
}
.swiper-slide{
    // filter: blur(2px) !important;
    transform: scale(0.8) !important;
    transition: all 0.2s;

}
.swiper-slide-active{
    // filter: blur(0) !important;
    transform: scale(1) !important;
}
.swiper__tools {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 5rem;
    padding: 0 4rem 0 2rem;
}
.swiper__buttons {
    display: flex;
    button {
        position: relative;
        background-color: transparent;
        padding: 0.5rem 2rem;
        border-radius: 50px;
        border: 1px solid #fff;
        margin-right: 1rem;
        user-select: none;
        @media (min-width:370px) {
            padding: 0.5rem 3rem;
        }
        &:after {
            display: none;
        }
        img {
            filter: invert(100%);
        }
        &:hover {
            background-color: #ffffff21;
        }
        
    }
}
.swiper-pagination {
    position: relative;
}
.swiper-pagination-bullet {
    width: 35px;
    height: 4px;
    border-radius: 50px;
    background: #fff;
}
.swiper-pagination-bullet-active {
    width: 70px;
}
