@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/bigboz');
@import url('https://fonts.cdnfonts.com/css/duty-beauty');

                
*{
    margin: 0;
    box-sizing: border-box;
    &:after, 
    &:before {
        box-sizing: border-box;
    }
}
html,
body {
    overflow-x: hidden;
}
body {
    position: relative;
    font-family: 'Poppins', 'Orbitron',  'Duty Beauty', sans-serif;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    height: 100vh;
}
button,
input,
textarea,
select {
    font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6{
    overflow-wrap: break-word;
}
img,
video,
canvas,
svg {
    max-width: 100%;
}
a{
    text-decoration: none;
    color: inherit;
}
ul {
    list-style: none;
}
// Variables
$green-color: #6cff8d;
$dark-green-color: #6cff8c7a;
$red-color: #f44336;
$blue-color: #2196f3;
$yellow-color: #ffea5c;
$darkish-blue: #060b11;
$box-shadow-blue1: rgba(31,38,135,0.27);
$box-shadow-blue2: rgba(31,38,135,0.67);

// Predefined classes
.section {
    padding: 1rem;
    overflow: hidden;
    min-height: 100vh;
    padding-top: 5rem;
    @media (min-width:650px) {
        padding: 5rem;
    }
    &__header {
        &-title {
            font-weight: 400;
            font-size: 17px;
            color: #666;
        }
        &-subtitle {
            font-size: 27px;
            font-weight: 700;
        }
    }
}

