.projects {
    &__header {
        font-size: 60px;
        font-style: italic;
        padding-bottom: 10px;
    }
}
.project {
    &__header {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 1rem;
        font-family: sans-serif;
        span {
            border-top: 1px solid #000;
            padding: 1rem;
            font-size: 1rem;
            font-size: 30px;
            text-align: center;
        }
    }
    &__infos {
        margin-top: 1rem;
        &--name {
            position: relative;
            font-size: 3vw;
            font-family: sans-serif;
            display: flex;
            align-items: center;
            font-weight: 400;
        }
    }
    &__img {
        position: relative;
        width: 100vw;
        height: 700px;
        img {
            width: 60vw;
            height: 600px;
            object-fit: cover;
            position: absolute;
            right: 10vw;
            top: 3rem;
        }
    }
    &__links {
        padding-top: 3rem;
        button {
            &:before {
                background: #000;
            }
            &:after {
                display: none;
            }
        }
    }
    &__tags {
        position: absolute;
        bottom: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
.coolFunnyLink {
    position: relative;
    font-family: sans-serif;
    svg {
        position: absolute;
        bottom: -5px;
        left: 0;
        stroke-width: 1px;
        width: 95px;
        path {
            stroke-dasharray: 1;
            stroke-dashoffset: 1;
            transition: stroke-dashoffset 0.6s cubic-bezier(0.7, 0, 0.3, 1);
        }
    }
    &:hover {
        svg path {
            stroke-dashoffset: 0;
            transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
        }
    }
}
.coolCircleEyeButton {
    position: relative;
    display: inline-block;
    .textcircle {
        display: block;
        width: 250px;
        animation: rotate 9s linear infinite;
        text {
            font-size: 32px;
            text-transform: uppercase;
            fill: #000;
        }
    }
    @keyframes rotate {
        to {
            transform: rotate(-360deg);
        }
    }
    &:hover{
        text {
            font-weight: 500;
        }
        .eye__inner, 
        .eye__iris, 
        .eye__lashes-up {
            animation: hide 2s step-end infinite;
            @keyframes hide {
                0%{
                    opacity: 0;
                }
                10%{
                    opacity: 1;
                }
            }
        }
        .eye__lashes-down {
            animation: show 2s step-end infinite;
            @keyframes show {
                0%{
                    opacity: 1;
                }
                10%{
                    opacity: 0;
                }
            }
        }
    }
}
.eye {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &__outer, &__inner, &__lashes-up,&__lashes-down {
        stroke: #000;
        fill: none;
        stroke-width: 1.5px;
    }
    &__lashes-down {
        opacity: 0;
    }
}
@media (max-width:1350px) {
    .projects {
        padding: 3rem;
    }
    .project__img img {
        width: 74vw;
        right: 5rem;
    }
    .project__infos--name {
        font-size: 38px;
    }
    .project .coolCircleEyeButton {
        transform: translate(-14rem, -3rem);
    }
}
@media (max-width:750px) {
    .projects {
        padding: 1rem;
    }
    .project__tags {
        flex-direction: row;
        top: 0;
        left: 0;
    }
    .project__infos--name {
        font-size: 35px;
        span {
            display: none;
        }
    }
    .project__img img {
        width: 85vw;
        right: 3rem;
    }
}
@media (max-width:440px) {
    .project .coolCircleEyeButton {
        transform: translate(0);
    }
    .project__tags {
        gap: 6px;
        font-size: 14px;
    }
    .project__header span {
        font-size: 25px;
    }
}

